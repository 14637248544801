import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import Block from "../components/common/Block/Block"
import CTA from "../components/common/CTA"
import EbookDownload from "../components/common/EbookDownload/EbookDownload"
import FeatureList from "../components/common/Feature-list/FeatureList"
import Flowchart from "../components/common/Flowchart/Flowchart"
import Frame from "../components/common/frame"
import Hero from "../components/common/Hero/Hero"
import Integrations from "../components/common/Integrations/Integrations"
import MarqueeImages from "../components/common/MarqueeImages/MarqueeImages"
import ScrollContainer from "../components/common/Scrolling-container/Scrolling-container"
import SimpleBlock from "../components/common/Simple-block/SimpleBlock"
import TabToggler from "../components/common/Tab-toggler/TabToggler"
import Testimonial from "../components/common/testimonial-component/testimonial"
import VerticalTabs from "../components/common/VerticalTabComponent/VerticalTabs"
import Navigation from "../components/navigation"
import CTAThankYou from "../components/utility/cta_thank_you"
import Slide from "../components/utility/slide_content"
import "../styles/features-details-v2.scss"
import "../styles/features.scss"

function FeatureDetailsV2({ pageContext }) {
  const { feature } = pageContext

  let navBgColor = feature.heroBgColor
  navBgColor = navBgColor.slice(
    navBgColor.indexOf("#"),
    navBgColor.indexOf("#") + 7
  )

  return (
    <>
      <StaticQuery
        query={graphql`
          query contentFeatureDetailsV2 {
            SuperOps {
              pages(where: { title: "Feature Details" }) {
                title
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              return (
                <div className="feature-details-v2">
                  <Frame seo={feature.seos[0]} newButton>
                    <header>
                      <Navigation
                        page="About us"
                        color={navBgColor}
                        newButton
                      />
                    </header>

                    <section className="section-wrapper">
                      {feature.components.map((field, ind) => {
                        switch (field.__typename) {
                          case "SuperOps_Hero": {
                            return (
                              <>
                                <Hero
                                  backgroundImage={field.backgroundColor}
                                  tag={field.tag}
                                  heading={field.heading.text}
                                  maxDescriptionWidth="500px"
                                  description={field.heroExcerpt}
                                  benefits={field.heroExcerptList}
                                  primaryButtonText={field.primaryButtonText}
                                  primaryButtonLink={field.primaryButtonLink}
                                  secondaryButtonText={
                                    field.secondaryButtonText
                                  }
                                  secondaryButtonLink={
                                    field.secondaryButtonLink
                                  }
                                  image={field.heroImage.url}
                                  imageMob={field.heroImageMob?.url}
                                  newButton
                                  videoURL={field.tagLink}
                                  hidePulsate
                                />
                              </>
                            )
                          }
                          case "SuperOps_Field": {
                            return (
                              <div className={`${ind > 1 && "mt100"}`}>
                                <MarqueeImages
                                  heading={field.name}
                                  images={field.images}
                                />
                              </div>
                            )
                          }
                          case "SuperOps_Benefit": {
                            if (field.benefitType === "Integrations")
                              return (
                                <Integrations
                                  heading={parse(field.benefitsContent[0].html)}
                                />
                              )
                            else return <SimpleBlock data={field} />
                          }
                          case "SuperOps_FaqComponent": {
                            return <VerticalTabs data={field} />
                          }
                          case "SuperOps_Testimonial": {
                            return (
                              <Testimonial
                                type={field.testimonialType}
                                testimonial={field}
                              />
                            )
                          }
                          case "SuperOps_Block": {
                            return (
                              <div
                                id={`pageBlk-${ind + 1}`}
                                className={`${
                                  field.blockTextPosition === "Right"
                                    ? "text-right"
                                    : "text-left"
                                }`}
                              >
                                <h2 className="font-roboto text-center fw-bold mx-auto block-title">
                                  {field.blockHeading}
                                </h2>
                                <Block
                                  html={field?.blockContent?.html}
                                  imageTitle={field.blockImageTitle}
                                  image={field.blockImage}
                                  isImage={field.blockIsIllustration}
                                  isVideo={field.blockIsVideo}
                                  lgLeft={5}
                                  lgRight={7}
                                  decoration
                                />
                              </div>
                            )
                          }
                          case "SuperOps_Advantage": {
                            if (field.advantageType === "tab_switch")
                              return (
                                <TabToggler
                                  heading={field.advantagesHeading[0]}
                                  title={field.advantagesExcerpt}
                                  images={field.advantagesIcon}
                                />
                              )
                            else return <Flowchart data={field} />
                          }
                          case "SuperOps_Card": {
                            if (field.cardType === "Features")
                              return (
                                <div className="Layout-container mx-auto">
                                  <FeatureList
                                    title={field.title[0]}
                                    heading={field.text}
                                    description={field.subtext}
                                    image={field.image}
                                  />
                                </div>
                              )
                            else return <EbookDownload data={field} />
                          }
                          case "SuperOps_MultipleCard": {
                            if (field.cardType === "Plain_cards") {
                              return (
                                <div className="plain-blocks">
                                  <Slide delay="200">
                                    <h2 className="text-center font-roboto fw-bold mb50">
                                      {field.cards[0].cardName}
                                    </h2>
                                  </Slide>
                                  {field.cards.map((a, b) => {
                                    return (
                                      <div
                                        className={`${
                                          b % 2 ? "text-right" : "text-left"
                                        }`}
                                      >
                                        <Block
                                          heading={a.heading}
                                          description={a.text}
                                          imageTitle={[""]}
                                          image={a.image}
                                          isImage={["Yes"]}
                                          isVideo={["no"]}
                                          lgLeft={5}
                                          lgRight={7}
                                        />
                                      </div>
                                    )
                                  })}
                                </div>
                              )
                            } else
                              return (
                                <ScrollContainer
                                  data={field.cards}
                                  topValue="250"
                                  leftColumn={5}
                                  rightColumn={6}
                                  justifyEnd
                                  wrap
                                />
                              )
                          }
                          case "SuperOps_CtaFeature": {
                            return (
                              <section className="mt80">
                                <CTA
                                  data={[field.ctaBox]}
                                  className="Layout-container"
                                  lgLeft={7}
                                  lgRight={4}
                                  rightStyles={{ alignItems: "center" }}
                                  newButton
                                  newDesign
                                />
                              </section>
                            )
                          }
                        }
                      })}
                    </section>
                    <CTAThankYou />
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </>
  )
}

export default FeatureDetailsV2
